import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { login, logout } from '../redux/action';
export default function Header(props) {
  const { setMenuExpand, menuExpand } = props;
  const dispatch = useDispatch()
  const LoginState = useSelector((state) => state.LoginState);
  const allurl = localStorage.getItem("URL")
  const [url, setURL] = React.useState(allurl)

  return (
    <div className="row header-design"
    >
      <div className="col-md-4">
        <div className="bars-position"
          onClick={() => {
            setMenuExpand(!menuExpand)
          }}
        >
          <i className="fa fa-bars" style={{ fontSize: "20px" }} />

        </div>
      </div>
      <div className="col-md-4">
        <input className="col-12"
          value={url}
          onChange={(event) => { setURL(event.target.value) }}
          placeholder="url"
        />
        <span style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("URL", url)
            window.location.reload();
          }}
        > Go</span>
      </div>
      <div className="col-md-4">
        <div className="name-header-postion">
          <Link to={"/profile"} className="Link">
            {LoginState.userName}&nbsp;&nbsp;
          </Link>
          <i className="fa fa-sign-out" style={{ fontSize: "20px" }}
            onClick={() => {
              dispatch(logout())
            }}
          />
        </div>
      </div>
    </div>
  )
}