import React from "react";

import Database from '../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import FormComponent from "../Exam/Components/Form";
import ExamData from '../Exam/Components/examData.json'
export default function NewChapter() {
  // const FetchData = Database.chapter_wise_exam;
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_block_text": ExamData.meta.exam_question_block_text,
      "exam_time": "",
      "exam_details": "",
      "available_unavailable": "Unavailable",
      "featured_image": "",
      "exam_type": ""
    }
  };
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Chapter</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_chapters}>All Chapters</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
        db_slug={slugs.db_slug_chapter_wise_exam}
        webSlug={slugs.chapter_details}
      />
    </div>
  )
}